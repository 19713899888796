<div class="row m-0 mb-1 p-1">
  <div class="col-12 m-0 mb-1 p-1">
    <img
      src="https://www.newkadia.com/A/NewKadia20-200x366.png"
      alt="NewKadia.com - 20 years online, 2000-2020"
      style="display:block;margin:1em auto;width:90%;"
    />
    <h5 class="nkred">Sell Your Comics</h5>
    <p class="indent">
      <img
        src="https://img.newkadia.com/A/jimdrucker.jpg"
        alt="Jim Drucker"
        align="left"
        class="pad25_5 sm"
        style="width:25%;"
      />Hi, I'm Jim Drucker of NewKadia.
    </p>
    <p class="indent">
      I'll help you get good value for your comics. Join 800 other sellers who
      get top dollar.
    </p>
    <p class="indent">
      We orchestrate every detail – grading, appraising and listing each book
      for sale and marketing it to buyers worldwide. We have a proven record in
      maximizing your profit. We’ve sold over 2.3 million comics.
    </p>
    <p class="indent">Read about us. Then, call me at the number below.</p>
    <p style="text-indent:0;">
      <span class="nkred strong">1. Make More Money With Us</span><br />Here are
      the amounts you get if you’re selling fewer than 5,000 comics.
    </p>
    <div class="row m-0 p-0 nkbgbluegreen white strong">
      <div class="col-5 m-0 p-1">You get *</div>
      <div class="col-7 m-0 p-1">when a book sells for</div>
    </div>
    <div class="row m-0 p-0 nkrow1">
      <div class="col-5 m-0 p-1 strong text-center">80%</div>
      <div class="col-7 m-0 p-1">$500 or more</div>
    </div>
    <div class="row m-0 p-0 nkrow2">
      <div class="col-5 m-0 p-1 strong text-center">70%</div>
      <div class="col-7 m-0 p-1">$250 to $499.99</div>
    </div>
    <div class="row m-0 p-0 nkrow1">
      <div class="col-5 m-0 p-1 strong text-center">60%</div>
      <div class="col-7 m-0 p-1">$100 to $249.99</div>
    </div>
    <div class="row m-0 p-0 nkrow2">
      <div class="col-5 m-0 p-1 strong text-center">55%</div>
      <div class="col-7 m-0 p-1">$50 to $99.99</div>
    </div>
    <div class="row m-0 p-0 nkrow1">
      <div class="col-5 m-0 p-1 strong text-center">50%</div>
      <div class="col-7 m-0 p-1">$12.50 to $49.99</div>
    </div>
    <div class="row m-0 p-0 nkrow2">
      <div class="col-5 m-0 p-1 strong text-center">Read examples</div>
      <div class="col-7 m-0 p-1">under $12.50</div>
    </div>
    <div class="row m-0 mb-1 p-0">
      <div class="col-12 m-0 p-1 nkbgbluegreen white small strong">
        * Less a <u>per book fee</u> described below
      </div>
    </div>
    <p class="indent strong mb-0">* The per book fee is:</p>
    <p class="m-0" style="text-indent:2em;">A. $3.99, or</p>
    <p class="mt-0" style="text-indent:2em;">
      B. $2.99 for books with a cover price 35¢ or less if you send 250 or more
      with a cover price 35¢ or less.
    </p>
    <p class="indent">
      The fee is our charge for unpacking, bagging and boarding, storage,
      insurance, and labor.
    </p>
    <p class="indent">
      If your book fee is $2.99, you receive more than 20¢ only when a book
      sells for $6.38 or more. If your book fee is $3.99, you receive more than
      20¢ only when a book sells for $8.38 or more.
    </p>
    <p style="text-indent:0;">
      <span class="nkred strong">2. It’s Easy</span><br />You’ll save lots of
      time vs. selling on eBay. For each comic, will you have to&hellip;
    </p>
    <div class="row m-0 p-0 strong">
      <div class="col-5 m-0 p-1 nkbgbluegreen text-right" />
      <div class="col-5 m-0 p-1 nkrow2 text-center">
        <img src="https://www.imagesnk.com/A/LogoTrans98.png" alt="NewKadia" />
      </div>
      <div class="col-2 m-0 p-1 nkrow1 text-center">eBay</div>
    </div>
    <div class="row m-0 p-0 strong">
      <div class="col-5 m-0 p-1 nkbgbluegreen text-right">Grade it?</div>
      <div class="col-5 m-0 p-1 nkrow2 text-center">No</div>
      <div class="col-2 m-0 p-1 nkrow1 text-center">Yes</div>
    </div>
    <div class="row m-0 p-0 strong">
      <div class="col-5 m-0 p-1 nkbgbluegreen text-right">Write an ad?</div>
      <div class="col-5 m-0 p-1 nkrow2 text-center">No</div>
      <div class="col-2 m-0 p-1 nkrow1 text-center">Yes</div>
    </div>
    <div class="row m-0 p-0 strong">
      <div class="col-5 m-0 p-1 nkbgbluegreen text-right">Scan cover?</div>
      <div class="col-5 m-0 p-1 nkrow2 text-center">No</div>
      <div class="col-2 m-0 p-1 nkrow1 text-center">Yes</div>
    </div>
    <div class="row m-0 p-0 strong">
      <div class="col-5 m-0 p-1 nkbgbluegreen text-right">Buy a box?</div>
      <div class="col-5 m-0 p-1 nkrow2 text-center">No</div>
      <div class="col-2 m-0 p-1 nkrow1 text-center">Yes</div>
    </div>
    <div class="row m-0 p-0 strong">
      <div class="col-5 m-0 p-1 nkbgbluegreen text-right">Pack it up?</div>
      <div class="col-5 m-0 p-1 nkrow2 text-center">No</div>
      <div class="col-2 m-0 p-1 nkrow1 text-center">Yes</div>
    </div>
    <div class="row m-0 p-0 strong">
      <div class="col-5 m-0 p-1 nkbgbluegreen text-right">Type a label?</div>
      <div class="col-5 m-0 p-1 nkrow2 text-center">No</div>
      <div class="col-2 m-0 p-1 nkrow1 text-center">Yes</div>
    </div>
    <div class="row m-0 p-0 strong">
      <div class="col-5 m-0 p-1 nkbgbluegreen text-right">Mail it?</div>
      <div class="col-5 m-0 p-1 nkrow2 text-center">No</div>
      <div class="col-2 m-0 p-1 nkrow1 text-center">Yes</div>
    </div>
    <div class="row m-0 mb-4 p-0 strong">
      <div class="col-5 m-0 p-1 nkbgbluegreen text-right">
        Deal with lost packages?
      </div>
      <div class="col-5 m-0 p-1 nkrow2 text-center">No</div>
      <div class="col-2 m-0 p-1 nkrow1 text-center">Yes</div>
    </div>
    <p class="mb-0" style="text-indent:0;">
      <span class="nkred strong">3. Get Paid</span><br />Each month you get a
      check for books sold. After 48 months, for unsold books, you tell us to:
    </p>
    <p class="m-0" style="text-indent:2em;">a. Keep selling them, or</p>
    <p class="mt-0" style="text-indent:2em;">
      b. Return them to you (at our expense).
    </p>
    <hr />
    <h5 class="nkred mb-3">Frequently Asked Questions</h5>
    <p style="text-indent:0;" class="mb-0 strong">
      How long has NewKadia been in business?
    </p>
    <p class="indent">
      Since Jan. 1, 2000. That’s over 20 years.<br /><img
        src="https://www.newkadia.com/A/NewKadia20-200x366.png"
        alt="NewKadia.com - 20 years online, 2000-2020"
        style="display:block;margin:1em auto;width:60%;"
      />
    </p>
    <p style="text-indent:0;" class="mb-0 strong">Can I track my sales?</p>
    <p class="indent">
      You get a private page on our website that lists every comic, the date it
      sold and the amount it sold for.
    </p>
    <p style="text-indent:0;" class="mb-0 strong">
      Why does NewKadia pay more than brick and mortar comic stores?
    </p>
    <p class="indent">
      In 1999, I wanted to sell my own collection. I sent a list of my 800
      comics to 27 stores. The Overstreet Price Guide said they were worth
      $10,400. The only offer I got was $325. So I started selling my comics
      online.
    </p>
    <p class="indent">
      Now, we sell a quarter of a million comics annually - every type of comic,
      every era and publisher.
    </p>
    <p class="indent">We pay a higher percentage because:</p>
    <p class="indent">
      &bull; Our expenses are much lower than brick and mortar stores.
    </p>
    <p class="indent">
      &bull; We sell so many more comics than others, we can pay a higher
      percentage per book. What we give up per book, we make up in volume.
    </p>
    <p class="indent">
      &bull; With customers worldwide, we can turn your comic books into cash
      faster than anyone.
    </p>
    <p style="text-indent:0;" class="mb-0 strong">How big is NewKadia?</p>
    <p class="indent">
      We're the biggest internet-only comic store in the world, selling over
      22,000 comics per month.
    </p>
    <img
      src="https://img.newkadia.com/A/warehouse200w.jpg"
      alt="Jim Drucker, NewKadia warehouse"
      style="display:block;margin:0.5em auto;width:80%;"
    />
    <p class="indent">
      In 2019, over 1.6 million shoppers visited our website – 133,000 each
      month.
    </p>
    <p style="text-indent:0;" class="mb-0 strong">What are my comics worth?</p>
    <p class="indent">
      It's impossible to give an exact value until we grade them because each
      one’s value is based on what issue it is and its condition.
    </p>
    <p class="indent">
      Key factors are supply and demand. Many want the first Superman comic
      (from 1938), but only a few exist. A copy recently sold for $3.2 million.
    </p>
    <p class="indent">
      In contrast, the first issue of New Mutants (from 1983) is in great supply
      and fewer people want one. It's worth $28.
    </p>
    <p class="indent">
      A comic's real value is what someone is willing to pay. And so, if a comic
      isn't selling, we drop its price a tiny percentage each month (about 1¢ on
      a $5 comic) until one of our 1.6 million store visitors buys it.
    </p>
    <p class="indent">
      Also remember, your profit on a comic is its sale price, MINUS your cost,
      in time and advertising, to find a buyer.
    </p>
    <p class="indent">
      What would it cost you to reach 1.6 million comic buyers? How much time
      would it take to ship each of your book’s?
    </p>
    <p class="indent">
      Subtract these costs and the value of your time from each book's sale
      price. That’s your real net profit. Or, have NewKadia sell for you. When
      we sell a book, our percentage and per book fee are your only costs. And
      you don't pay them unless and until we sell the book.
    </p>
    <p style="text-indent:0;" class="strong">
      What determines how fast my comics sell?
    </p>
    <p style="text-indent:0;" class="mb-0 strong">&bull; Condition</p>
    <p class="indent mt-0">
      <img
        class="pad25_5 sm"
        src="https://www.newkadia.com/A/flashrunning1.jpg"
        alt="Flash Running"
        align="right"
      />When we have several copies of the same comic, the one in the best
      condition usually sells fastest (collectors want the best). Copies in the
      worst condition also sell because they are much less expensive.
    </p>
    <p style="text-indent:0;" class="mb-0 strong">&bull; Popularity</p>
    <p class="indent mt-0">
      We stock 18,000 different titles. Amazing Spider-Man (1963 series) is our
      fastest selling title. Our top seller list appears on our desktop version
      of NewKadia.com
    </p>
    <p style="text-indent:0;" class="mb-0 strong">
      How does NewKadia find buyers?
    </p>
    <p class="indent">
      We spend $200,000 annually on advertising. And 50,000 current customers
      get our weekly emails.
    </p>
    <p style="text-indent:0;" class="strong">Are my books safe and insured?</p>
    <p class="indent">
      <img
        class="pad25_5 sm"
        src="https://www.imagesnk.com/125NobleStreet.jpg"
        alt="NewKadia warehouse"
        align="left"
      />Yes. Our fulfillment center is fully insured. We occupy the third floor
      of this brick building. Insurance protects our inventory from fire, theft
      and storm damage. In 20 years, we have never suffered any loss.
    </p>
    <p class="indent">
      Our facility is protected by two burglar alarms. One protects the entire
      building; a second protects our space.
    </p>
    <h5 class="nkred">Call me</h5>
    <p class="indent">
      <img
        src="https://img.newkadia.com/A/jimdrucker.jpg"
        alt="Jim Drucker"
        align="left"
        class="pad25_5 sm"
        style="width:25%;"
      />Weekdays 10 am to 5 pm EST<br />at 610-277-3000.
    </p>
    <p class="indent">
      Ask me questions and get references from people who have made money with
      us.
    </p>
    <p class="indent">
      After we talk, I can email you shipping labels and a 2-page agreement
      describing everything.
    </p>
    <p class="indent">
      Call today or tomorrow and start turning your comics into cash!
    </p>
  </div>
</div>
